import icons from "./icons";
//MENU PRINCIPAL
const menu = {
  firstLevel: [
    {
      name: "Tableau de Bord",
      icon: icons.dashboard,
      path: "/site/dashboard",
      activate: "dashboard",
      id: 1,
    },
    {
      name: "Offres Emploi",
      icon: icons.rh,
      path: "/site/offres-emploi",
      activate: "offres-emploi",
      id: 2,
    },
    {
      name: "Appels d'Offres",
      icon: icons.rh,
      path: "/site/appel-offres",
      activate: "appel-offres",
      id: 3,
    },
    {
      name: "Questions",
      icon: icons.rh,
      path: "/site/questions",
      activate: "questions",
      id: 4,
    },
  ],
  secondLevel: [
    {
      name: "Paramètres",
      icon: icons.param,
      path: "/param",
      activate: "param",
      id: 6,
    },
    {
      name: "Deconnexion",
      icon: icons.logout,
      path: "/login",
      id: 7,
    },
  ],
};
const menuSite = [
  {
    name: "Accueil",
    link: "/",
    anchor: false,
    sousMenu: [],
  },
  {
    name: "A Propos de Nous",
    link: "/#apropos",
    anchor: true,
    sousMenu: [],
  },
  {
    name: "Nos Services",
    link: "/#nos-services",
    anchor: true,
    sousMenu: [],
  },
  {
    name: "Nos Réalisations",
    link: "/#nos-realisations",
    anchor: true,
    sousMenu: [],
  },
  {
    name: "Opportunités",
    link: "/#",
    anchor: true,
    sousMenu: [
      {
        name: "offres d'emploi",
        link: "/offres-emploi",
        anchor: false,
      },
      {
        name: "appels d'offres",
        link: "/appels-offres",
        anchor: false,
      },
    ],
  },
  {
    name: "Contactez-Nous",
    link: "/#contact",
    anchor: true,
    sousMenu: [],
  },
];
const menuSiteMob = [
  {
    name: "Accueil",
    link: "/",
    anchor: false,
    sousMenu: [],
  },
  {
    name: "A Propos de Nous",
    link: "/#apropos",
    anchor: true,
    sousMenu: [],
  },
  {
    name: "Nos Services",
    link: "/#nos-services",
    anchor: true,
    sousMenu: [],
  },
  {
    name: "Nos Réalisations",
    link: "/#nos-realisations",
    anchor: true,
    sousMenu: [],
  },

  {
    name: "offres d'emploi",
    link: "/offres-emploi",
    anchor: false,
    sousMenu: [],
  },
  {
    name: "appels d'offres",
    link: "/appels-offres",
    anchor: false,
    sousMenu: [],
  },
  {
    name: "Contactez-Nous",
    link: "/#contact",
    anchor: true,
    sousMenu: [],
  },
];
const apps = [
  {
    name: "G-SITE WEB",
    link: "/site/dashboard",
    icon: icons.site_web,
    active: true,
  },
  {
    name: "GARAGE",
    link: "/garage/dashboard",
    icon: icons.garage,
    activate: false,
  },
  {
    name: "Ressources Humaines",
    link: "/rh/dashboard",
    icon: icons.rhs,
    activate: false,
  },
];
//MENU TABULATIONS

const tabsSiteEmploi = [
  {
    id: 0,
    name: "Toutes",
    description: "liste des offres d'emploi",
    clause: "",
  },
  {
    id: 1,
    name: "En cours",
    description: "liste des offres d'emploi en cours",
    clause: "?status=0",
  },
  {
    id: 2,
    name: "Expriré",
    description: "liste des offres d'emploi exprirées ",
    clause: "?status=1",
  },
];
const tabsSitePostulant = [
  {
    id: 0,
    name: "Tous",
    description: "liste des postulants au poste ",
    clause: "",
  },
  {
    id: 1,
    name: "En Lecture",
    description: "liste des postulants en status lecture au poste ",
    clause: "?statusId=1",
  },
  {
    id: 2,
    name: "Invalidés",
    description: "liste des postulants en status invalidés au poste ",
    clause: "?statusId=2",
  },
];

const tabsSiteQuestions = [
  {
    id: 0,
    name: "Toutes",
    description: "toutes les questions de cotation ",
    clause: "",
  },
];
export default {
  menu,
  menuSite,
  menuSiteMob,
  apps,
  tabsSiteEmploi,
  tabsSitePostulant,
  tabsSiteQuestions,
};
