export const loginImage = require("../assets/images/login.png");
export const avatar = require("../assets/images/default.png");
export const logo = require("../assets/images/logo.png");
export const imageHome = require("../assets/images/main-home.jpg");
export const empty = require("../assets/images/empty.png");

export const about1 = require("../assets/images/about-1.jpg");
export const about2 = require("../assets/images/about-2.jpg");
export const about3 = require("../assets/images/about-3.jpg");

export const service1 = require("../assets/images/service-1.jpg");
export const service2 = require("../assets/images/service-2.jpg");
export const service3 = require("../assets/images/service-3.jpeg");
export const service4 = require("../assets/images/service-4.png");
export const service5 = require("../assets/images/service-5.jpg");
export const service6 = require("../assets/images/service-6.jpg");
export const service7 = require("../assets/images/service-7.jpg");
export const service8 = require("../assets/images/service-8.jpg");
export const service9 = require("../assets/images/service-9.jpg");

export const real1 = require("../assets/images/real-01.jpg");
export const real2 = require("../assets/images/real-02.jpg");
export const real3 = require("../assets/images/real-03.jpg");
export const real4 = require("../assets/images/real-04.jpg");
export const real5 = require("../assets/images/real-05.jpg");
export const real6 = require("../assets/images/real-06.jpg");
export const real7 = require("../assets/images/real-07.jpg");
export const real8 = require("../assets/images/real-08.jpg");
export const real9 = require("../assets/images/real-09.jpg");
export const real10 = require("../assets/images/real-10.jpg");
export const real11 = require("../assets/images/real-11.jpg");
export const real12 = require("../assets/images/real-12.jpg");
export const real13 = require("../assets/images/real-13.jpg");

export const part1 = require("../assets/images/part-01.png");
export const part2 = require("../assets/images/part-02.png");
export const part3 = require("../assets/images/part-03.png");
export const part4 = require("../assets/images/part-04.png");
export const part5 = require("../assets/images/part-05.png");
export const part6 = require("../assets/images/part-06.png");
export const part7 = require("../assets/images/part-07.png");
export const part8 = require("../assets/images/part-08.png");
export const part9 = require("../assets/images/part-09.png");
export const part10 = require("../assets/images/part-10.png");
export const part11 = require("../assets/images/part-11.png");
export const part12 = require("../assets/images/part-12.png");
export const part13 = require("../assets/images/part-13.png");
export const part14 = require("../assets/images/part-14.png");
export const part15 = require("../assets/images/part-15.png");

export default {
  loginImage,
  logo,
  avatar,
  imageHome,
  empty,
  about1,
  about2,
  about3,
  service1,
  service2,
  service3,
  service4,
  service5,
  service6,
  service7,
  service8,
  service9,
  real1,
  real2,
  real3,
  real4,
  real5,
  real6,
  real7,
  real8,
  real9,
  real10,
  real11,
  real12,
  real13,
  part1,
  part2,
  part3,
  part4,
  part5,
  part6,
  part7,
  part8,
  part9,
  part10,
  part11,
  part12,
  part13,
  part14,
  part15,
};
