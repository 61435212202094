import React, { useState } from "react";
import { icons } from "../../constant";

const TedCheckBox = ({ name, label, fxOnchange }) => {
  const [state, setState] = useState(false);
  const changedState = (e) => {
    setState(!state);
    e.target.name = name;
    e.target.value = state ? 0 : 1;
    fxOnchange(e);
  };
  return (
    <div className="checkbox" onClick={changedState}>
      <div className={`checkbox-btn ${state ? "active" : "no-active"}`}>
        {state && <span>{icons.checked_input}</span>}
      </div>
      <span>{label}</span>
    </div>
  );
};

export default TedCheckBox;
