import React from "react";
import { icons } from "../../../../constant";
const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div onClick={onClick} className="slider-arrow arrow-left">
      {icons.goLeft}
    </div>
  );
};
export default PrevArrow;
