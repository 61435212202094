import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Dropdown } from "antd";
import {
  setError,
  setLoading,
  setIsSaved,
  setOpenModal,
  setUniqueId,
} from "../../../../../../global-state/redux";
import { urls, images, icons, data } from "../../../../../../constant";
import { getRequest } from "../../../../../../services/httpRequest";
import { MenuOptionOffreEmploi } from "../../../../../../reusables";
import { TedEmpty } from "../../../../../../core-ui";
const configHeaderTable = [
  "Titre",
  "Affectation",
  "Type",
  "Status",
  "Date publication",
  "Date Fin",

  "Postulants",
  "Action",
];
const typesJobs = data.typesJobs;
const status = [
  { descr: "En cours", color: "success" },
  { descr: "Expriré", color: "danger" },
];

const ListeOffresEmploi = ({ tabIndex }) => {
  const history = useHistory();
  const [datas, setDatas] = useState([]);
  const { isSaved } = useSelector((store) => store.loading);

  const dispatch = useDispatch();
  const getDatas = async () => {
    dispatch(setLoading());
    const { err, msg, data, typeErr } = await getRequest(
      `${urls.emploi}${tabIndex.clause}`
    );
    dispatch(setLoading());
    if (err) {
      return dispatch(setError({ err, msg, data, typeErr }));
    }
    setDatas(data);
  };
  useEffect(() => {
    getDatas();
  }, [isSaved, tabIndex.id]);

  // Fx for actions
  const goToPostulant = (item) => {
    const ID = `${item.id}--${item.uuid}`;
    dispatch(setUniqueId(ID));
    history.push("/site/postulants");
  };
  const deleteContent = (item) => {
    dispatch(
      setOpenModal({
        actionName: "deleteContent",
        data: item,
        actionTitle: `suppression d'une offre d'emploi ${item.title}`,
        number: 0,
      })
    );
  };

  const editContent = (item) => {
    dispatch(setOpenModal({ actionName: "createData", data: item }));
  };

  return (
    <div>
      <table className="table-normal">
        <thead>
          <tr>
            {configHeaderTable.map((i, idx) => {
              return <td key={idx}>{i}</td>;
            })}
          </tr>
        </thead>
        <tbody>
          {datas &&
            datas.map((item) => {
              const {
                id,
                uuid,
                title,
                location,
                type,
                date_debut,
                date_fin,
                state,
                path,
                createdAt,
                updatedAt,
                totalPostulant,
                totalInvalider,
              } = item;
              return (
                <tr key={id}>
                  <td>{title}</td>
                  <td>{location}</td>
                  <td>{typesJobs[type - 1]}</td>
                  <td>
                    <span className={`status ${status[state].color}`}>
                      {status[state].descr}
                    </span>
                  </td>
                  <td>{date_debut}</td>
                  <td>{date_fin}</td>

                  <td>{`${totalPostulant} / ${totalInvalider}`}</td>
                  <td>
                    <Dropdown
                      overlay={MenuOptionOffreEmploi(
                        goToPostulant,
                        deleteContent,
                        editContent,
                        item
                      )}
                      placement="bottomRight"
                    >
                      <div className="">
                        <span>{icons.action}</span>
                      </div>
                    </Dropdown>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      {datas && datas < 1 && (
        <TedEmpty
          icon={icons.empty}
          description={"Aucune offre d'emploi publiée"}
        />
      )}
    </div>
  );
};

export default ListeOffresEmploi;
