import React from "react";
import "./Container.css";
import { Header, Footer, Menu } from "../../component";
import { TedErrorPopup } from "../../../../core-ui";
import { useSelector, useDispatch } from "react-redux";
import { closeError } from "../../../../global-state/redux";
const Container = ({ children }) => {
  const configError = useSelector((store) => store.error);
  const dispatch = useDispatch();
  return (
    <>
      <div className="container-site">
        <Header />
        <Menu />
        <div className="main-site">{children}</div>
        <Footer />
      </div>
      <TedErrorPopup
        errorsConfig={configError} //  const errors = { messageError: msg, showError: err, typeError: typeErr,};
        fxClosePopup={() => dispatch(closeError())}
      />
    </>
  );
};

export default Container;
