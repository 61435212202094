const stateApp = "redux"; //value = redux or context to change the state management of the app
const designApp = "v0"; //la version du design de l'app for now we have v0:default v1, v2
const date = new Date();
const nameApp = "SOLIDEC";
const nameAppLong = "SOLIDEC";
const welcomeMessage = "Se connecter";
const footer = `© SOLIDEC - ${date.getFullYear()}. Tous droits réservés`;

export default {
  stateApp,
  designApp,
  nameApp,
  nameAppLong,
  welcomeMessage,
  footer,
};
