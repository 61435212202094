import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Dropdown } from "antd";
import {
  setError,
  setLoading,
  setIsSaved,
  setOpenModal,
  setUniqueId,
} from "../../../../../../global-state/redux";
import { urls, images, icons } from "../../../../../../constant";
import { getRequest } from "../../../../../../services/httpRequest";
import { MenuOptionPostulant } from "../../../../../../reusables";
import { formatterDateAndHour } from "../../../../../../helpers";
import { TedEmpty, TedDropdown } from "../../../../../../core-ui";
const configHeaderTable = ["Description", "Type", "Points", "Action"];
const typesJobs = ["Full Time", "Part Time"];
const status = [
  { descr: "Qualité", color: "info" },
  { descr: "Eligibilité", color: "info" },
];

const ListQuestion = () => {
  const history = useHistory();
  const { isSaved } = useSelector((store) => store.loading);
  const dispatch = useDispatch();
  const [datas, setDatas] = useState([]);

  const getDatas = async () => {
    dispatch(setLoading());
    const { err, msg, data, typeErr } = await getRequest(`${urls.question}`);
    dispatch(setLoading());
    if (err) {
      return dispatch(setError({ err, msg, data, typeErr }));
    }
    console.log(data);
    setDatas(data);
  };
  useEffect(() => {
    getDatas();
  }, [isSaved]);

  // Fx for actions
  //   const deleteContent = (item) => {
  //     dispatch(
  //       setOpenModal({
  //         actionName: "deleteContent",
  //         data: item,
  //         actionTitle: `suppression du postulant ${item.nom} ${item.prenom}`,
  //         number: 1,
  //       })
  //     );
  //   };

  //   const filterContent = (item) => {
  //     dispatch(
  //       setOpenModal({
  //         actionName: "actionContent",
  //         data: item,
  //         actionTitle: `FILTRER LE POSTULANT ${item.nom} ${item.prenom}`,
  //         number: 1,
  //       })
  //     );
  //   };

  const configdropDown = [
    {
      name: "Détails",
      icons: icons.action_details,
      fx: (item) => {},
    },
    {
      name: "Editer",
      icons: icons.action_edit,
      fx: "onEdit",
    },
    {
      name: "Supprimer",
      icons: icons.action_delete,
      fx: (item) => {},
    },
  ];
  return (
    <div>
      <table className="table-normal">
        <thead>
          <tr>
            {configHeaderTable.map((i, idx) => {
              return <td key={idx}>{i}</td>;
            })}
          </tr>
        </thead>
        <tbody>
          {datas &&
            datas.map((item) => {
              const { id, title, type, marks, createdAt } = item;
              return (
                <tr key={id}>
                  <td>{title}</td>
                  <td>
                    <span className={`status ${status[type - 1].color}`}>
                      {status[type - 1].descr}
                    </span>
                  </td>
                  <td>{marks}</td>

                  <td>
                    <div className="actions">
                      <TedDropdown
                        configdropDown={configdropDown}
                        data={item}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      {/* {datas && datas.postulants.length < 1 && (
        <TedEmpty
          icon={icons.empty}
          description={"Aucun postulant sur ce poste"}
        />
      )} */}
    </div>
  );
};

export default ListQuestion;
