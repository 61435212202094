import React, { useEffect, useState } from "react";
import { icons } from "../../constant";

const TedMultiSelect = ({ options, name, onChange }) => {
  const [showOptions, setShowOptions] = useState(false);
  const [value, setValue] = useState({ value: [], option: [] });
  const [optionsList, setOptionsList] = useState([]);

  const setSelectValue = (e, val) => {
    const newValue = value;
    const findIndex = newValue.option.find((opt) => opt.id === val);
    if (findIndex) {
      let newValueOption = newValue.option;
      let newValueValue = newValue.value;
      newValueOption = newValueOption.filter((item) => item.id !== val);
      newValueValue = newValueValue.filter((item) => item.id_multisect !== val);
      newValue.option = newValueOption;
      newValue.value = newValueValue;
    } else {
      newValue.value = [...newValue.value, { id_multisect: val }];
      newValue.option = [
        ...newValue.option,
        { id: val, name: e.target.textContent },
      ];
    }

    e.target.name = name;
    e.target.value = undefined;
    e.target.multiselect = newValue.value;
    // console.log("TEST ", e.target.multiselect);
    onChange(e);
    setValue(newValue);
  };

  const _removeItem = (id) => {
    // const newValueOption = value.option.filter((item) => item.id !== id);
    // newValue.option = newValueOption;
    // setValue({
    //   ...value,
    //   newValues,
    // });
  };

  const searchItem = (e) => {
    const value = e.target.value;
    const oldOptions = options;
    if (value == "") {
      setOptionsList(oldOptions);
    } else {
      const newOptions = oldOptions.filter((item) => {
        const descr = item.description.toLowerCase();
        const val = value.toLowerCase();
        if (descr.includes(val)) {
          return item;
        }
      });
      setOptionsList(newOptions);
    }
  };
  useEffect(() => {
    setOptionsList(options);
  }, [options]);

  //   console.log(value);

  return (
    <div
      className={`select mutliselect ${
        showOptions || value.value ? "float" : "no-float"
      }`}
    >
      <div
        className="bar-multi-select"
        onClick={() => setShowOptions(!showOptions)}
      >
        <div className="options">
          {value.option.map((item) => (
            <span
              key={item.id}
              className="options-select"
              onClick={() => _removeItem(item.id)}
            >
              {item.name}
            </span>
          ))}
        </div>
        <span>{showOptions ? icons.close : icons.down}</span>
      </div>
      <div className={`select-options ${showOptions ? "show-options" : ""}`}>
        <div className="container-search">
          <div className="search">
            <span>{icons.search}</span>
            <input
              type="text"
              placeholder="Rechercher ici ..."
              onChange={(e) => searchItem(e)}
            />
          </div>
        </div>
        <ul className="multi-select-ul">
          {optionsList.map((item, idx) => {
            return (
              <Item
                key={idx}
                fxOnClick={setSelectValue}
                value={value}
                item={item}
                icons={icons}
              />
            );
          })}
        </ul>
      </div>
    </div>
  );
};

const Item = ({ fxOnClick, value, item, icons }) => {
  const { id, description } = item;
  const [checked, setChecked] = useState(false);

  const setChecking = (e) => {
    setChecked(!checked);
    fxOnClick(e, id);
  };
  return (
    <li
      //   onClick={(e) => fxOnClick(e, id)}
      onClick={(e) => setChecking(e)}
    >
      <span>{!checked ? icons.unchecked : icons.checked}</span>
      {description}
    </li>
  );
};

export default TedMultiSelect;
