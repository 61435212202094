import React from "react";
import "./TedButton.css";
import PropTypes from "prop-types";

import { TedLoader } from "../";

const TedButton = ({ type, classe, label, fxOnClick, loading }) => {
  return (
    <button type={type} className={classe} onClick={fxOnClick}>
      {loading ? <TedLoader size="min" /> : <span>{label}</span>}
    </button>
  );
};

TedButton.propTypes = {
  type: PropTypes.oneOf(["button", "submit", "reset"]).isRequired,
  classe: PropTypes.string.isRequired,
  label: PropTypes.any.isRequired,
  fxOnClick: PropTypes.func.isRequired,
};

export default TedButton;
