import React from "react";
import "./Home.css";
import {
  Container,
  Presentation,
  About,
  NosServices,
  PourquoiNous,
  Realisation,
  Contact,
  Partenaire,
} from "../../component";

const Home = () => {
  return (
    <Container>
      <Presentation page="home" />
      <div id="apropos">
        <About />
      </div>
      <div className="main-pourquoi-nous">
        <PourquoiNous />
      </div>
      <div id="nos-services">
        <NosServices />
      </div>
      <div id="nos-realisations" className="main-realisation">
        <Realisation />
      </div>
      <div id="nos-partenaire">
        <Partenaire />
      </div>
      <div id="contact"></div>
      <Contact />
    </Container>
  );
};

export default Home;
