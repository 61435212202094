import { icons } from "../../constant";
import { Menu } from "antd";
import "./TableActions.css";

const MenuOptionOffreEmploi = (
  goToPostulant,
  deleteContent,
  editContent,
  data
) => {
  console.log(data);
  return (
    <Menu
      items={[
        {
          key: "0",
          label: (
            <div className="tab-action" onClick={() => goToPostulant(data)}>
              {icons.action_postulant} <span>Liste de postulant</span>
            </div>
          ),
        },
        {
          key: "1",
          label: (
            <a
              className="tab-action"
              href={data.path}
              download={data.title}
              target="_blank"
            >
              {icons.action_download} <span>Voir l'offre</span>
            </a>
          ),
        },
        {
          key: "2",
          label: (
            <div className="tab-action" onClick={() => editContent(data)}>
              {icons.action_edit} <span>Editer</span>
            </div>
          ),
        },
        {
          key: "3",
          label: (
            <div className="tab-action" onClick={() => deleteContent(data)}>
              {icons.action_delete} <span>Supprimer</span>
            </div>
          ),
        },
      ]}
    />
  );
};
const MenuOptionPostulant = (filterContent, deleteContent, data) => {
  return (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <div className="tab-action" onClick={() => filterContent(data)}>
              {icons.action_filtre} <span>Filtrer</span>
            </div>
          ),
        },
        {
          key: "2",
          label: (
            <div className="tab-action" onClick={() => deleteContent(data)}>
              {icons.action_delete} <span>Supprimer</span>
            </div>
          ),
        },
      ]}
    />
  );
};

const MenuOptionAppelsOffres = () => {
  return (
    <Menu
      items={[
        {
          key: "1",
          label: <div>{icons.edit} Editer</div>,
        },
        {
          key: "2",
          label: <div>{icons.delete} Supprimer</div>,
        },
        {
          key: "3",
          label: <div>{icons.rhs} Postulants</div>,
        },
      ]}
    />
  );
};

export { MenuOptionOffreEmploi, MenuOptionPostulant, MenuOptionAppelsOffres };
