import React from "react";
import "./About.css";
import { images } from "../../../../constant";
const About = () => {
  return (
    <div className="about">
      <div className="about_images">
        <figure className="figure01">
          <img src={images.about1} alt="image a propos de nous" />
        </figure>
        <figure className="figure02">
          <img src={images.about2} alt="image a propos de nous" />
        </figure>
        <figure className="figure03">
          <img src={images.about3} alt="image a propos de nous" />
        </figure>
      </div>
      <div className="about_content">
        <h2 className="site-sous-title-h2">
          A propos de <span>SOLIDEC</span>
        </h2>
        <div>
          <h3 className="site-sous-title-h3">Notre Mission</h3>
          <p>
            Nous avons pour mission de faire grandir l'expérience de la main
            d'oeuvre congolaise afin que les congolais participent activement au
            développement continuel du pays.
          </p>
        </div>
        <div>
          <h3 className="site-sous-title-h3">Notre Histoire</h3>
          <p>
            Créer en 2009 à l'Est de la RDC, nous avons exécutés plus de 50
            projets de construction (routes, centres de santé, écoles, adduction
            d'eau, marchés, bâtiments administratifs, entrepôt, ponts,
            immeubles, ...) pour le compte de nos partenaires.
          </p>
        </div>
        <div>
          <h3 className="site-sous-title-h3">Notre Objectif</h3>
          <p>
            Notre objectif est de reduire le taux de chômage, produire des
            ouvrages de qualité. Nous créons des opportunités de carrière et
            nous faisons cela en nous basant sur la croissance de nos bénéfices.
          </p>
        </div>

        <div className="about-lire-plus">
          <a href="#" className="btn-page bg-full">
            Lire plus
          </a>
        </div>
      </div>
    </div>
  );
};
export default About;
