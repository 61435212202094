import React, { useReducer, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DatePicker } from "antd";
import { Formulaire } from "../../../../../../components";
import { TedModal, TedButton } from "../../../../../../core-ui";
import {
  setError,
  setLoading,
  setIsSaved,
  setOpenModal,
} from "../../../../../../global-state/redux";
import { urls, images, icons } from "../../../../../../constant";
import {
  getRequest,
  postRequest,
  patchRequest,
} from "../../../../../../services/httpRequest";
import { MenuOptionOffreEmploi } from "../../../../../../reusables";
import {
  reducer,
  configFormCreateOffreEmploi,
  defaultValueFormCreateOffreEmploi,
} from "../../../../../../reducers";

const CreateOffreEmploi = () => {
  const {
    open,
    number,
    actionName,
    data: dataModal,
  } = useSelector((store) => store.modal);
  const { isloading } = useSelector((store) => store.loading);
  const dispatch = useDispatch();
  const [configForm, dispatchConfig] = useReducer(
    reducer,
    configFormCreateOffreEmploi
  );
  const [defaultValue, dispatchDefautlValue] = useReducer(
    reducer,
    defaultValueFormCreateOffreEmploi
  );

  const createOffre = async () => {
    dispatch(setLoading());
    var formData = new FormData();
    formData.append("title", defaultValue.title);
    formData.append("location", defaultValue.location);
    formData.append("type", defaultValue.type);
    formData.append("date_fin", defaultValue.date_fin);
    formData.append("file", defaultValue.file);
    const datas = formData;
    let requestData;
    if (typeof dataModal === "undefined") {
      requestData = await postRequest(`${urls.emploi}`, "", datas);
    } else {
      requestData = await patchRequest(
        `${urls.emploi}/${dataModal.id}`,
        "",
        datas
      );
    }
    const { err, msg, data, typeErr } = requestData;
    if (typeErr === "failed") {
      dispatch(setLoading());
      return dispatch(setError({ err, msg, data, typeErr }));
    }
    dispatch(setLoading());
    dispatch(setError({ err, msg, data, typeErr }));
    dispatch(setOpenModal({ number: 0 }));
    return dispatch(setIsSaved());
  };

  useEffect(() => {
    dispatchDefautlValue({
      type: "SET_DATA_TO_UPDATE_OFFRE_EMPLOI",
      payload: dataModal,
    });
  }, []);

  return (
    <TedModal
      title={
        typeof dataModal === "undefined"
          ? "publié une offre d'emploi"
          : `modifié l'offre ${dataModal.title}`
      }
      isOpen={open}
      fxCloseAndOpen={() => dispatch(setOpenModal({ number: 0 }))}
      size="moyen"
    >
      <div className="form-postuler ">
        <div>
          <Formulaire
            fields={configForm.firstOne}
            dispatch={dispatchDefautlValue}
            values={defaultValue}
            align="column"
          />
        </div>
        <div>
          <Formulaire
            fields={configForm.secondOne}
            dispatch={dispatchDefautlValue}
            values={defaultValue}
            align="column"
          />
        </div>
      </div>
      <div className="save-btn-modal">
        <TedButton
          type="button"
          classe={`${typeof dataModal === "undefined" ? "primary" : "info"}`}
          label={`${
            typeof dataModal === "undefined" ? "ENREGISTRER" : "MODIFIER"
          }`}
          loading={isloading}
          fxOnClick={() => createOffre()}
        />
      </div>
    </TedModal>
  );
};

export default CreateOffreEmploi;
