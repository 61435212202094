import "./Questions.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setError, setLoading } from "../../../../../global-state/redux";
import { data, urls, menu } from "../../../../../constant";
import { getRequest } from "../../../../../services/httpRequest";
import { Container, HeaderPager, MenuPager } from "../../../../../components";
import { ActionContent } from "../../../../../reusables";
import { ListQuestion, CreateQuestion } from "./small";

const tabs = menu.tabsSiteQuestions;
const Questions = () => {
  const dispatch = useDispatch();
  const { open, number, actionName } = useSelector((store) => store.modal);
  const [tabIndex, setTabIndex] = useState(tabs[0]);
  const [statique, setStatique] = useState([]);
  const tabChanging = (data) => {
    setTabIndex(data);
  };
  return (
    <>
      <Container pageName={"questions"}>
        <HeaderPager title={tabIndex.description} btnTitle="Ajouter" showBtn />
        <MenuPager tabs={tabs} fxSelectedtab={tabChanging} />
        {tabIndex.id === 0 && <ListQuestion tabIndex={tabIndex} />}
      </Container>
      {open && actionName === "createData" && <CreateQuestion />}
    </>
  );
};
export default Questions;
