import {
  FaLock,
  FaUser,
  FaHome,
  FaFolder,
  FaHandshake,
  FaDollarSign,
  FaChartBar,
  FaBoxOpen,
  FaDolly,
  FaBell,
  FaCog,
  FaPowerOff,
  FaPlus,
  FaCheckCircle,
  FaExclamationCircle,
  FaTimesCircle,
  FaCaretDown,
  FaRegCheckCircle,
  FaRegCircle,
  FaTimes,
  FaRegFilePdf,
  FaRegEye,
  FaDownload,
  FaCloudDownloadAlt,
  FaSearch,
  FaPencilAlt,
  FaTrashAlt,
  FaPlay,
  FaFileMedical,
  FaQuestion,
  FaRegEdit,
  FaRegKeyboard,
  FaGlassWhiskey,
  FaCheck,
  FaShoppingCart,
  FaFacebookF,
  FaLinkedinIn,
  FaTwitter,
  FaWhatsapp,
  FaAngleRight,
  FaAngleLeft,
  FaEraser,
  FaUmbrella,
  FaCheckSquare,
  FaCubes,
  FaEnvelope,
  FaPhoneAlt,
  FaMapMarkerAlt,
  FaBusinessTime,
  FaStopwatch,
  FaRegCalendarAlt,
  FaGlobeAfrica,
  FaUsers,
  FaCar,
  FaEllipsisV,
  FaBars,
  FaFilter,
} from "react-icons/fa";

const icons = {
  user: <FaUser color="#073042" size={14} />,
  email: <FaEnvelope color="#073042" size={14} />,
  phone: <FaPhoneAlt color="#073042" size={14} />,
  map: <FaMapMarkerAlt color="#073042" size={14} />,
  password: <FaLock color="#073042" size={20} />,
  dashboard: <FaHome color="#ffffff" size={15} />,
  rapport: <FaChartBar color="#ffffff" size={15} />,
  reunions: <FaHandshake color="#ffffff" size={15} />,
  finance: <FaDollarSign color="#ffffff" size={15} />,
  rh: <FaBoxOpen color="#ffffff" size={15} />,
  materiel: <FaDolly color="#ffffff" size={15} />,
  param: <FaCog color="#ffffff" size={15} />,
  logout: <FaPowerOff color="#ffffff" size={15} />,
  notification: <FaBell color="#0369a1" size={25} />,
  success: <FaCheckCircle color="#047857" size={35} />,
  failed: <FaTimesCircle color="#be123c" size={35} />,
  failedd: <FaTimesCircle color="#be123c" size={25} />,
  add: <FaPlus color="#ffffff" size={13} />,
  adds: <FaPlus color="#fff" size={13} />,
  down: <FaCaretDown color="#0e7490" size={20} />,
  checked: <FaRegCheckCircle color="#ffffff" size={15} />,
  unchecked: <FaRegCircle color="#000000" size={20} />,
  close: <FaTimes color="#be123c" size={15} />,
  close_white: <FaTimes color="#fff" size={15} />,
  pdf: <FaRegFilePdf color="#047857" size={30} />,
  voir: <FaRegEye color="#fff" size={9} />,
  download: <FaDownload color="#fff" size={9} />,
  download_file: <FaCloudDownloadAlt color="#0e7490" size={60} />,
  download_file_true: <FaCloudDownloadAlt color="#047857" size={60} />,
  search: <FaSearch color="#44403c" size={25} />,
  pencil: <FaPencilAlt color="#44403c" size={25} />,
  delete: <FaTrashAlt color="#44403c" size={25} />,
  play: <FaPlay color="#ffffff" size={20} />,
  file: <FaFileMedical color="#44403c" size={25} />,
  question: <FaQuestion color="#44403c" size={25} />,
  text: <FaRegKeyboard color="#44403c" size={25} />,
  edite: <FaRegEdit color="#44403c" size={25} />,
  empty: <FaGlassWhiskey color="#be123c" size={50} />,
  checked_input: <FaCheck color="#ffffff" size={20} />,
  deleted: <FaTrashAlt color="#fff" size={15} />,
  histo: <FaExclamationCircle color="#fff" size={15} />,
  edit: <FaRegEdit color="#fff" size={15} />,
  stock: <FaShoppingCart color="#fff" size={15} />,
  fb: <FaFacebookF color="#fff" size={15} />,
  linkedin: <FaLinkedinIn color="#fff" size={15} />,
  twitter: <FaTwitter color="#fff" size={15} />,
  whatsapp: <FaWhatsapp color="#fff" size={15} />,
  webmail: <FaEnvelope color="#fff" size={15} />,
  goLeft: <FaAngleLeft color="#fff" size={15} />,
  goRight: <FaAngleRight color="#fff" size={15} />,
  speed: <FaEraser color="#243b4a" size={60} />,
  quality: <FaCheckSquare color="#243b4a" size={60} />,
  perfermance: <FaUmbrella color="#243b4a" size={60} />,
  equipage: <FaCubes color="#243b4a" size={60} />,
  jobs: <FaBusinessTime color="#a8a8ad7b" size={50} />,

  maps: <FaMapMarkerAlt color="#073042" size={15} />,
  time: <FaStopwatch color="#073042" size={15} />,
  calendar: <FaRegCalendarAlt color="#073042" size={15} />,
  btn_download: <FaDownload color="#0369a1" size={15} />,

  site_web: <FaGlobeAfrica color="#a8a8ad7b" size={30} />,
  rhs: <FaUsers color="#a8a8ad7b" size={30} />,
  garage: <FaCar color="#a8a8ad7b" size={30} />,

  table_delete: <FaTrashAlt color="#b91c1c" size={15} />,
  action: <FaEllipsisV color="#243b4a" size={18} />,
  action_edit: <FaRegEdit color="#243b4a" size={15} />,
  action_delete: <FaTrashAlt color="#b91c1c" size={15} />,
  action_postulant: <FaUsers color="#243b4a" size={15} />,
  action_filtre: <FaFilter color="#243b4a" size={15} />,
  action_download: <FaDownload color="#243b4a" size={15} />,

  menuMob: <FaBars color="#fff" size={18} />,
  close_menu: <FaTimes color="#fff" size={25} />,
};

export default icons;
