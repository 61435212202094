import React, { useState } from "react";
import "./TedDropdown.css";
import { icons } from "../../constant";
const TedDropdown = ({ configdropDown, data }) => {
  const [showMenu, setShowMenu] = useState(false);
  return (
    <div className="dropdown btn" onMouseLeave={() => setShowMenu(false)}>
      <span onMouseEnter={() => setShowMenu(true)}>{icons.action}</span>
      <ul className={`${showMenu ? "visible" : "invisible"}`}>
        {configdropDown.map((item, idx) => {
          return (
            <li onClick={() => item.fx(data)} key={idx}>
              <span>{item.icons}</span>
              <span>{item.name}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default TedDropdown;
