import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { config } from "./constant";
import "./base-ui/reset.css";
import "./base-ui/main.css";
import App from "./App";
import { store, persistor } from "./global-state/redux/store";
import { PersistGate } from "redux-persist/lib/integration/react";
import { Provider } from "react-redux";
//decommenter cette ligne si tu vas utiliser le management du global state avec ContextAPI
// import { AppProvider } from "./global-state/context/context";

//all pages

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <Router>
      <PersistGate loading={null} persistor={persistor}>
        <main className={config.designApp}>
          <App />
        </main>
      </PersistGate>
    </Router>
  </Provider>
);
