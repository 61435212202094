import React, { useReducer, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DatePicker } from "antd";
import { Formulaire } from "../../../../../../components";
import { TedModal, TedButton } from "../../../../../../core-ui";
import {
  setError,
  setLoading,
  setIsSaved,
  setOpenModal,
} from "../../../../../../global-state/redux";
import { urls, images, icons } from "../../../../../../constant";
import {
  getRequest,
  postRequest,
  patchRequest,
} from "../../../../../../services/httpRequest";
import { MenuOptionOffreEmploi } from "../../../../../../reusables";
import {
  reducer,
  configFormCreateQuestion,
  defaultValueFormCreateQuestion,
} from "../../../../../../reducers";

const CreateQuestion = () => {
  const {
    open,
    number,
    actionName,
    data: dataModal,
  } = useSelector((store) => store.modal);
  const { isloading } = useSelector((store) => store.loading);
  const dispatch = useDispatch();
  const [configForm, dispatchConfig] = useReducer(
    reducer,
    configFormCreateQuestion
  );
  const [defaultValue, dispatchDefautlValue] = useReducer(
    reducer,
    defaultValueFormCreateQuestion
  );

  const createOffre = async () => {
    dispatch(setLoading());
    const datas = defaultValue;
    let requestData;
    if (typeof dataModal === "undefined") {
      requestData = await postRequest(`${urls.question}`, "", datas);
    } else {
      requestData = await patchRequest(
        `${urls.question}/${dataModal.id}`,
        "",
        datas
      );
    }
    const { err, msg, data, typeErr } = requestData;
    if (typeErr === "failed") {
      dispatch(setLoading());
      return dispatch(setError({ err, msg, data, typeErr }));
    }
    dispatch(setLoading());
    dispatch(setError({ err, msg, data, typeErr }));
    dispatch(setOpenModal({ number: 0 }));
    return dispatch(setIsSaved());
  };

  useEffect(() => {
    // dispatchDefautlValue({
    //   type: "SET_DATA_TO_UPDATE_OFFRE_EMPLOI",
    //   payload: dataModal,
    // });
  }, []);

  return (
    <TedModal
      title={
        typeof dataModal === "undefined"
          ? "ajouter une question"
          : `modifié la question ${dataModal.title}`
      }
      isOpen={open}
      fxCloseAndOpen={() => dispatch(setOpenModal({ number: 0 }))}
      size="moyen-01"
    >
      <div className="">
        <div>
          <Formulaire
            fields={configForm.firstOne}
            dispatch={dispatchDefautlValue}
            values={defaultValue}
            align="column"
          />
        </div>
        <div>
          <Formulaire
            fields={configForm.secondOne}
            dispatch={dispatchDefautlValue}
            values={defaultValue}
            align="column"
          />
        </div>
      </div>
      <div className="save-btn-modal">
        <TedButton
          type="button"
          classe={`${typeof dataModal === "undefined" ? "primary" : "info"}`}
          label={`${
            typeof dataModal === "undefined" ? "ENREGISTRER" : "MODIFIER"
          }`}
          loading={isloading}
          fxOnClick={() => createOffre()}
        />
      </div>
    </TedModal>
  );
};

export default CreateQuestion;
