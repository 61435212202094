const defaultValueFormLogin = {
  username: "",
  password: "",
};

const defaultValueFormContactezNous = {
  names: "",
  email: "",
  message: "",
};
const defaultValueFormCreateOffreEmploi = {
  title: "",
  location: "",
  type: "",
  date_fin: "",
  file: "",
};
const defaultValueFormCreatePostulerOffreEmploi = {
  nom: "",
  prenom: "",
  email: "",
  tel: "",
  file: "",
};

const defaultValueFormFilterStatus = {
  statusId: "",
};

const defaultValueFormCreateQuestion = {
  title: "",
  type: "",
  marks: "",
};
export {
  defaultValueFormLogin,
  defaultValueFormContactezNous,
  defaultValueFormCreateOffreEmploi,
  defaultValueFormCreatePostulerOffreEmploi,
  defaultValueFormFilterStatus,
  defaultValueFormCreateQuestion,
};
