import { TedLoader } from "../../core-ui";
import "./LoaderContent.css";
const LoaderContent = ({ description }) => {
  return (
    <div className="loader-content">
      <TedLoader size="big" bg={"primary"} />
      <div>
        <span>{description}</span>
      </div>
    </div>
  );
};

export default LoaderContent;
