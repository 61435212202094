import React from "react";
import "./Footer.css";
import { images, icons, menu } from "../../../../constant";
import { Link as LinkDom } from "react-router-dom";
import { config } from "../../../../constant";
import { Anchor } from "antd";
const { Link } = Anchor;
const Footer = () => {
  return (
    <div className="footer-site">
      <div className="foot_content">
        <div className="logo">
          <figure>
            <img src={images.logo} alt="logo solidec" />
          </figure>
          <p>
            <span className="anton">SOLIDEC</span> est une société du groupe
            <span className="rubik-gras"> Co-Brain Group</span> oeuvrant dans le
            domaine d'ingénierie civile en République Démocratique du Congo
            depuis 13 ans
          </p>
        </div>
        <div className="footer_menu">
          <nav>
            <Anchor affix={false}>
              <ul>
                <li>
                  <LinkDom to={"/"}>Accueil</LinkDom>
                </li>
                <li>
                  <Link
                    href={"/#apropos"}
                    title={"A propos de nous"}
                    className="site-link"
                  />
                </li>
                <li>
                  <Link
                    href={"/#nos-services"}
                    title={"Nos Services"}
                    className="site-link"
                  />
                </li>
                <li>
                  <Link
                    href={"/#nos-realisations"}
                    title={"Nos Réalisations"}
                    className="site-link"
                  />
                </li>
                <li>
                  <LinkDom to={"/offres-emploi"}>Offres d'emploi</LinkDom>
                </li>
                <li>
                  <LinkDom to={"/appels-offres"}>Appels d'offres</LinkDom>
                </li>
              </ul>
            </Anchor>
          </nav>
        </div>
        <div className="footer_contact">
          <h3 className="site-sous-title-h3 ">Contactez-nous</h3>

          <div className="contact-item">
            {icons.phone}
            <a href="tel:+243 822 000 452">+243 822 000 452</a>
          </div>
          <div className="contact-item">
            {icons.phone}
            <a href="tel:+243 999 965 003">+243 999 965 003</a>
          </div>
          <div className="contact-item">
            {icons.email}
            <a href="mailto:contact@solidec-cd.com">contact@solidec-cd.com</a>
          </div>
        </div>
        <div className="footer_addresse">
          <h3 className="site-sous-title-h3 ">Visitez nous</h3>
          <div className="addresse">
            <p>
              {icons.map}
              <span>
                Avenue Pumbu 05, Q.Clinic, Commune de la Gombe, Kinshasa
              </span>
            </p>
            <p>
              {icons.map}
              <span>Avenue Musée 49, Himbi I, Goma</span>
            </p>
            <p>
              {icons.map}
              <span>Avenue Patrice 409, Ibanda, Bukavu</span>
            </p>
          </div>

          <div className="sociaux-media">
            <a href="#">{icons.fb}</a>
            <a href="#">{icons.twitter}</a>
            <a href="#">{icons.linkedin}</a>
            <a href="#">{icons.whatsapp}</a>
            <a href="https://webmail1.hostinger.com" target={"_blank"}>
              {icons.webmail}
            </a>
          </div>
        </div>
      </div>
      <div className="droit-auteur">{config.footer}</div>
    </div>
  );
};
export default Footer;
