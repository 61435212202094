import React from "react";
import "./Empty.css";
import { images } from "../../../../constant";
const Empty = ({ img, description }) => {
  return (
    <div className="empty">
      <figure>
        <img src={img} alt="aucun contenu disponible" />
      </figure>
      <p>{description}</p>
    </div>
  );
};
export default Empty;
