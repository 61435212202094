import React from "react";
import "./NosServices.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { icons, data } from "../../../../constant";
import { NextArrow, PrevArrow } from "../slider";
import { useSelector, useDispatch } from "react-redux";
const NosServices = () => {
  const {
    screens: { isPhonePortrait, isTablette, isDesktop, isBigDesktop },
  } = useSelector((store) => store.data);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: isBigDesktop ? 3 : isDesktop ? 3 : isTablette ? 2 : 1,
    slidesToScroll: isBigDesktop ? 3 : isDesktop ? 3 : isTablette ? 2 : 1,
    nextArrow: <NextArrow props />,
    prevArrow: <PrevArrow props />,
  };
  return (
    <div className="nos-services">
      <div className="title">
        <h2 className="site-sous-title-h2">
          NOS <span>SERVICES</span>
        </h2>
      </div>
      <div>
        <Slider {...settings}>
          {data.servicesData.map((item, idx) => {
            return <CardSerice {...item} key={idx} />;
          })}
        </Slider>
      </div>
    </div>
  );
};
const CardSerice = ({ name, img, description }) => {
  return (
    <div className="services_card">
      <div className="services_card-img">
        <figure>
          <img src={img} alt={name} />
        </figure>
      </div>
      <div className="services_card-content">
        <h3 className="site-sous-title-h3">{name}</h3>
        <p>{description}</p>
      </div>
      <div className="nos-services_lire-plus">
        <a href="#" className="btn-page bg-light primary">
          Lire plus
        </a>
      </div>
    </div>
  );
};

export default NosServices;
