import React from "react";
import "./Partenaire.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { icons, data, urls } from "../../../../constant";
import { NextArrow, PrevArrow } from "../slider";
import { useSelector, useDispatch } from "react-redux";
const Partenaire = () => {
  const {
    screens: { isPhonePortrait, isTablette, isDesktop, isBigDesktop },
  } = useSelector((store) => store.data);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: isBigDesktop ? 8 : isDesktop ? 6 : isTablette ? 5 : 3,
    slidesToScroll: isBigDesktop ? 8 : isDesktop ? 6 : isTablette ? 5 : 3,
    // nextArrow: <NextArrow props />,
    // prevArrow: <PrevArrow props />,
  };
  return (
    <div className="partenaires">
      <div className="title">
        <h2 className="site-sous-title-h2">
          NOS <span>Partenaires</span>
        </h2>
      </div>
      <div className="bloc-content_partenaire">
        {/* <Slider {...settings}> */}
        {data.nosPartenairesData.map((item, idx) => {
          return <LogoPartenaire {...item} key={idx} />;
        })}
        {/* </Slider> */}
      </div>
    </div>
  );
};

const LogoPartenaire = ({ img, description }) => {
  return (
    <div className="partenaire_image" style={{}}>
      <figure>
        <img src={img} alt="" />
      </figure>
    </div>
  );
};

export default Partenaire;
