import React, { useEffect, useReducer } from "react";
import "./Container.css";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

//Depends components

import { Header, NavBar, Footer } from "../";
import { PdfSeeAllPages } from "../../";
import { TedLoader, TedErrorPopup } from "../../../core-ui/";

//import default state for reducers
import { defaultStateLoader, reducer } from "../../../reducers";
import { DeleteContent } from "../../../reusables";

//redux
import { useSelector } from "react-redux";
//constant
import { icons } from "../../../constant";
import { closeError } from "../../../global-state/redux";

const Container = ({ pageName, children }) => {
  const [state, dispatchLoader] = useReducer(reducer, defaultStateLoader);
  const configError = useSelector((store) => store.error);
  const { open, data, number, actionName } = useSelector(
    (store) => store.modal
  );
  const dispatch = useDispatch();
  useEffect(() => {
    setTimeout(() => {
      dispatchLoader({ type: "CHANGE_CLASS_LOADER_TO_HIDDEN" });
    }, 1000);
  }, [pageName]);

  return (
    <>
      <div className="container-app">
        <div className="navigation">
          <NavBar />
        </div>
        <div className="content-main">
          <Header pageName={pageName} />
          <main className="main-container-app">
            <div className={state.loaderClass}>
              <TedLoader />
            </div>
            <div className={state.containerClass}>{children}</div>
          </main>
          <Footer />
        </div>
      </div>
      <TedErrorPopup
        errorsConfig={configError} //  const errors = { messageError: msg, showError: err, typeError: typeErr,};
        fxClosePopup={() => dispatch(closeError())}
      />
      {open && actionName === "deleteContent" && <DeleteContent indexUrl={0} />}
    </>
  );
};

Container.propTypes = {
  pageName: PropTypes.any.isRequired,
};
export default Container;
