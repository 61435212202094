import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Dropdown } from "antd";
import {
  setError,
  setLoading,
  setIsSaved,
  setOpenModal,
  setUniqueId,
} from "../../../../../../global-state/redux";
import { urls, images, icons } from "../../../../../../constant";
import { getRequest } from "../../../../../../services/httpRequest";
import { MenuOptionPostulant } from "../../../../../../reusables";
import { formatterDateAndHour } from "../../../../../../helpers";
import { TedEmpty } from "../../../../../../core-ui";
const configHeaderTable = [
  "Nom",
  "Prénom",
  "Téléphone",
  "Email",
  "Date dépôt",
  "Status",
  "Dossier",
  "Action",
];
const typesJobs = ["Full Time", "Part Time"];
const status = [
  { descr: "Ouvert", color: "success" },
  { descr: "Fermé", color: "danger" },
];

const ListePostulantOffreEmploi = ({ data }) => {
  const history = useHistory();
  const { isSaved } = useSelector((store) => store.loading);
  const dispatch = useDispatch();

  // Fx for actions
  const deleteContent = (item) => {
    dispatch(
      setOpenModal({
        actionName: "deleteContent",
        data: item,
        actionTitle: `suppression du postulant ${item.nom} ${item.prenom}`,
        number: 1,
      })
    );
  };

  const filterContent = (item) => {
    dispatch(
      setOpenModal({
        actionName: "actionContent",
        data: item,
        actionTitle: `FILTRER LE POSTULANT ${item.nom} ${item.prenom}`,
        number: 1,
      })
    );
  };
  return (
    <div>
      <table className="table-normal">
        <thead>
          <tr>
            {configHeaderTable.map((i, idx) => {
              return <td key={idx}>{i}</td>;
            })}
          </tr>
        </thead>
        <tbody>
          {data &&
            data.postulants &&
            data.postulants.map((item) => {
              const {
                id,
                nom,
                prenom,
                tel,
                email,
                date_debut,
                status,
                path,
                is_cloudinary,
                createdAt,
              } = item;
              return (
                <tr key={id}>
                  <td>{nom}</td>
                  <td>{prenom}</td>
                  <td>{tel}</td>
                  <td>{email}</td>
                  <td>{formatterDateAndHour(createdAt)}</td>
                  <td>
                    <span className={`status ${status && status.color}`}>
                      {status ? status.description : "-"}
                    </span>
                  </td>
                  <td>
                    <a
                      href={
                        is_cloudinary === 1
                          ? path
                          : process.env.REACT_APP_URL_PUBLIC_FOLDER + path
                      }
                      download={`${nom}${prenom}`}
                      target="_blank"
                    >
                      {icons.btn_download}
                    </a>
                  </td>
                  <td>
                    <Dropdown
                      overlay={MenuOptionPostulant(
                        filterContent,
                        deleteContent,
                        item
                      )}
                      placement="bottomRight"
                    >
                      <div>
                        <span>{icons.action}</span>
                      </div>
                    </Dropdown>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      {data && data.postulants.length < 1 && (
        <TedEmpty
          icon={icons.empty}
          description={"Aucun postulant sur ce poste"}
        />
      )}
    </div>
  );
};

export default ListePostulantOffreEmploi;
