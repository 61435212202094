import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setError, setLoading } from "../../../../../global-state/redux";
import "./Emploi.css";
import { data, urls, menu } from "../../../../../constant";
import { getRequest } from "../../../../../services/httpRequest";
//all components
import { Container, HeaderPager, MenuPager } from "../../../../../components";

import { ListeOffresEmploi, CreateOffreEmploi } from "./small";
const tabs = menu.tabsSiteEmploi;
const Emploi = () => {
  const dispatch = useDispatch();
  const { open, number, actionName } = useSelector((store) => store.modal);
  const [tabIndex, setTabIndex] = useState(tabs[0]);
  const [statique, setStatique] = useState([]);
  const tabChanging = (data) => {
    setTabIndex(data);
  };

  return (
    <>
      <Container pageName={"Emploi"}>
        <HeaderPager title={tabIndex.description} btnTitle="Publier" showBtn />
        <MenuPager tabs={tabs} fxSelectedtab={tabChanging} />
        {(tabIndex.id === 0 || tabIndex.id === 1 || tabIndex.id === 2) && (
          <ListeOffresEmploi tabIndex={tabIndex} />
        )}
      </Container>
      {open && actionName === "createData" && (
        <CreateOffreEmploi statique={statique} />
      )}
    </>
  );
};

export default Emploi;
