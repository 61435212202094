import React, { useEffect, useState } from "react";
import "./OffresEmploi.css";
import {
  Container,
  Presentation,
  About,
  NosServices,
  PourquoiNous,
  Realisation,
  Contact,
} from "../../component";
import { TedLoader } from "../../../../core-ui";
import { useDispatch, useSelector } from "react-redux";
import { setLoading, setError } from "../../../../global-state/redux";
import { urls, images } from "../../../../constant";
import { getRequest } from "../../../../services/httpRequest";
import { ActionContent, LoaderContent } from "../../../../reusables";
import { CardJob, Empty } from "../../reusables";
const OffresEmploi = () => {
  const dispatch = useDispatch();
  const [emplois, setEmplois] = useState([]);
  const { isloading } = useSelector((store) => store.loading);
  const { open, actionName } = useSelector((store) => store.modal);
  const getEmplois = async () => {
    dispatch(setLoading());
    const { err, msg, data, typeErr } = await getRequest(`${urls.emploi}`);
    dispatch(setLoading());
    if (err) {
      return dispatch(setError({ err, msg, data, typeErr }));
    }
    setEmplois(data);
  };

  useEffect(() => {
    getEmplois();
  }, []);
  return (
    <>
      <Container>
        <Presentation page={"Offres d'emploi"} />
        <div className="offres-emaploi">
          {isloading && (
            <LoaderContent description={"chargement en cours ..."} />
          )}
          <div className="offre-listing">
            {emplois.map((item, idx) => (
              <CardJob key={idx} {...item} />
            ))}
          </div>
          {emplois.length < 1 && !isloading && (
            <Empty
              img={images.empty}
              description={"Aucune offre d'emploi disponible"}
            />
          )}
        </div>
      </Container>
      {open && actionName === "actionContent" && <ActionContent />}
    </>
  );
};

export default OffresEmploi;
