import React from "react";
import { icons } from "../../../../constant";
import "./Arrow.css";
const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div onClick={onClick} className="slider-arrow arrow-right">
      {icons.goRight}
    </div>
  );
};
export default NextArrow;
