const urls = {
  login: "/auth/login",
  dashboard: "/dashboard",
  emploi: "/emploi",
  postulant: "/postulant",
  statique: "/statique",
  question: "/question",
};

export default urls;
