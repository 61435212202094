import React from "react";
import { icons, data } from "../../../../constant";
import { setOpenModal } from "../../../../global-state/redux";
import { useDispatch, useSelector } from "react-redux";
import "./CardJob.css";
const status = [
  { descr: "En cours", color: "success" },
  { descr: "Expriré", color: "danger" },
];
const typeJobs = data.typesJobs;

const CardJob = (item) => {
  const dispatch = useDispatch();
  const { title, date_debut, id, date_fin, state, location, path, type } = item;
  return (
    <div className="cards-jobs">
      <div className="cards-jobs_logo">{icons.jobs}</div>
      <div className="cards-jobs_content">
        <div className="cards-jobs_title">
          <h3 className="site-sous-title-h3">{title}</h3>
          <span className={`status ${status[state].color}`}>
            {status[state].descr}
          </span>
        </div>
        <div className="cards-jobs_address cards_align">
          <p>
            {icons.maps}
            <span>{location}</span>
          </p>
          <p>
            {icons.time}
            <span>{typeJobs[type - 1]}</span>
          </p>
        </div>
        <div className="cards-jobs_dates cards_align">
          <p>
            {icons.calendar}
            <span>{date_debut}</span>
          </p>
          <p>-</p>
          <p>
            {icons.calendar}
            <span>{date_fin}</span>
          </p>
        </div>

        <div className="cards_align btns">
          <a
            href={path}
            download={`SOLIDEC_${title}`}
            className="download-btn"
            target={"_blank"}
          >
            {icons.btn_download} Télécharger
          </a>
          <div
            className="postuler-btn"
            onClick={() =>
              dispatch(
                setOpenModal({
                  actionName: "actionContent",
                  data: item,
                  actionTitle: `POSTULER SUR LE POSTE DE ${title}`,
                  number: 0,
                })
              )
            }
          >
            <span>Postuler</span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CardJob;
