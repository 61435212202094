import React from "react";
import "./Main.css";
import { icons, images, menu } from "../../../constant";

const Main = () => {
  return (
    <div className="main-app-choose">
      <div className="apps">
        <div className="welcome-message">
          <figure>
            <img src={images.avatar} alt="image du profiles" />
          </figure>
          <h1 className="rubik-gras">Bienvenue Teddy Walter</h1>
          <span>ADMINISTRATEUR</span>
        </div>
        <div className="list-item-apps">
          {menu.apps.map((item, idx) => {
            return <ItemApp key={idx} {...item} />;
          })}
        </div>
      </div>
    </div>
  );
};

const ItemApp = ({ name, icon, link, active }) => {
  return (
    <div className="item-app">
      <span>{icon}</span>
      <h2 className="rubik-gras">{name}</h2>
      <a href={link} target="_blank">
        Ouvrir
      </a>
    </div>
  );
};

export default Main;
