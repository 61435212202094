import React from "react";
import "./AppelOffres.css";
import {
  Container,
  Presentation,
  About,
  NosServices,
  PourquoiNous,
  Realisation,
  Contact,
} from "../../component";
import { images } from "../../../../constant";
import { Empty } from "../../reusables";

const AppelOffres = () => {
  return (
    <Container>
      <Presentation page={"Appel d'offres"} />
      <div className="offres-appels">
        <Empty
          img={images.empty}
          description={"Aucun Appel d'Offre disponible"}
        />
      </div>
    </Container>
  );
};

export default AppelOffres;
