import { createSlice } from "@reduxjs/toolkit";
import { startTransition } from "react";
import i18n from "../../../translations/i18n";

const initialState = {
  lang: i18n.language,
  screens: {
    isPhonePortrait: false,
    isTablette: false,
    isDesktop: false,
    isBigDesktop: false,
  },
  idDataNewLangue: 0,
  data_all: {},
};

const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    setLanguage: (state, { payload }) => {
      state.lang = payload;
    },
    setNewIdLangue: (state, { payload }) => {
      state.idDataNewLangue = payload;
    },
    setDataAll: (state, { payload }) => {
      state.data_all = payload;
    },
    setScreens: (state, { payload }) => {
      state.screens = payload;
    },
  },
});

export const { setLanguage, setNewIdLangue, setDt, setDataAll, setScreens } =
  dataSlice.actions;
export default dataSlice.reducer;
