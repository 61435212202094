import icons from "./icons";
import images from "./images";
const DATA_DASHBOARD_SITE = [
  {
    id: 1,
    number: 0,
    name: "Total Postulant",
    key: "postulant",
  },
  {
    id: 2,
    number: 0,
    name: "Offres d'emploi",
    key: "offres_emploi",
  },
  {
    id: 3,
    number: 0,
    name: "Appels d'offres",
    key: "appel_offre",
  },
  {
    id: 4,
    number: 0,
    name: "Messages",
    key: "messages",
  },
];

const pourquoiNousData = [
  {
    name: "Passion",
    icon: icons.speed,
    description:
      "Nous travaillons avec passion, volonté et détermination. Nous sommes impliqués dans chaque projet, et nous employons notre esprit et notre cœur pour construire la grandeur. ",
  },
  {
    name: "Excellence",
    icon: icons.quality,
    description:
      "Nous ne nous asseyons pas sur les lauriers de notre recherche de l’excellence, de la compétence et de la perfection. Être les meilleurs est la seule chose qui nous maintiendra toujours devant.",
  },

  {
    name: "PERFORMANCE ECONOMIQUE",
    icon: icons.perfermance,
    description:
      "La croissance de notre chiffre d'affaire, ansi que nos bénéfices mesurent combien nos clients valorisent nos services et notre efficacité à les fournir. Les bénéfices profitent à tous. ",
  },
  {
    name: "LA VALEUR DU MERITE",
    icon: icons.equipage,
    description:
      "Nous créons des opportunités de carrière et nous faisons cela en nous basant sur la croissance de nos bénéfices. Nous sommes simples, efficaces et reconnus pour nos compétences.",
  },
];
const servicesData = [
  {
    name: "Travaux de routes",
    img: images.service4,
    description:
      "Etudes complètes des routes, Réalisation d'accotements, Chaussées souples, Chaussées rigides, Réparation de voiries,  Ouverture des pistes, Ouvrage d'art",
  },
  {
    name: "construction hydrauliques",
    img: images.service2,
    description:
      "Captage des sources, Stockage en réservoir, 5 - 1200m3, Chambre des vannes, Fourniture et pose de la tuyauterie AG, HDPE, PVC, DN32-220, Borne fontaine, FP pompe solaire et manuel",
  },
  {
    name: "construction bâtiments",
    img: images.service3,
    description:
      "Conception d'avant projet sommaire, Conception et vente de plans complets, Réalisation de construction, ménuiserie métalique, divers finissage moderne des parois, sol, plafond",
  },
  {
    name: "genie informatique",
    img: images.service8,
    description:
      "Conception et réalisation des application web, des applications mobile, déploiement des applications sur serveur, Installation et configuration des réseaux informatiques,",
  },

  {
    name: "étude de projets",
    img: images.service9,
    description:
      "Topographie, Géologie, Hydrologie,  Hydraulique, Etude d'impact environnemental et sociale, Avant-projet sommaire, Informatique, Electrique, Architecture, Contre expertise de projets",
  },
  // { name: "bétonnage", img: images.service7, description: "" },
  // {
  //   name: "les gros oeuvres en général",
  //   img: images.service1,
  //   description: "",
  // },
];
const nosRealisationsData = [
  {
    img: images.real1,
    description: "construction route asphaltée, Kinshasa Gombe, partenaire ABC",
  },
  {
    img: images.real2,
    description: "immeuble R+7, Bukavu Sud-Kivu, partenaire Ste FL",
  },
  {
    img: images.real3,
    description: "bâtiment administratif, Lubero, partenaire PNUD",
  },
  {
    img: images.real4,
    description: "construction entrepôt, Goma, partenaire MERCI CORPS",
  },
  {
    img: images.real5,
    description: "construction 5 bâtiments d'école, Goma, partenaire KIS",
  },
  {
    img: images.real6,
    description:
      "construction réservoir 300m3, Kibumba Nord-Kivu, partenaire MDF ",
  },
  {
    img: images.real7,
    description:
      "construction 7 écoles , Lubutu et Walikale, partenaire The Jane Goodall Institute",
  },
  {
    img: images.real8,
    description: "travaux terrassement, Goma Nord Kivu, partenaire KIS",
  },
  // {
  //   img: images.real9,
  //   description: "une realisation de solidec",
  // },
  // {
  //   img: images.real10,
  //   description: "une realisation de solidec",
  // },
  {
    img: images.real13,
    description: "Adduction d'eau à kibumba, Nord kivu, partenaire MDF",
  },
];

const nosPartenairesData = [
  {
    img: images.part1,
  },
  {
    img: images.part2,
  },
  {
    img: images.part3,
  },
  {
    img: images.part4,
  },
  {
    img: images.part5,
  },
  {
    img: images.part6,
  },
  {
    img: images.part7,
  },
  {
    img: images.part8,
  },
  {
    img: images.part9,
  },
  {
    img: images.part10,
  },
  {
    img: images.part11,
  },
  {
    img: images.part12,
  },
  {
    img: images.part13,
  },
  {
    img: images.part14,
  },
  {
    img: images.part15,
  },
];
const typesJobs = ["Temps plein", "Temps partiel", "Consultant", "Stagiaire"];
export default {
  DATA_DASHBOARD_SITE,
  pourquoiNousData,
  servicesData,
  nosRealisationsData,
  typesJobs,
  nosPartenairesData,
};
