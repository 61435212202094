import React from "react";
import "./Header.css";
import { images, icons } from "../../../../constant";

const Header = () => {
  return (
    <div className="header-site">
      <div className="logo">
        <figure>
          <img src={images.logo} alt="logo solidec" />
        </figure>
        <div className="title">
          <span className="anton h1">SOLIDEC</span>
          <span className="rubik-lighter">
            Solid Engineering's Coorporation
          </span>
        </div>
      </div>
      <div className="address">
        <div>
          <p>Avenue Pumbu 05, Q.Clinic, Commune de la Gombe, Kinshasa</p>
          <a href="tel:+243 822 000 452">+243 822 000 452</a>
        </div>
        <div>
          <p>Avenue Musée 49, Q.Himbi I, Commune de Goma</p>
          <a href="tel:+243 999 965 003">+243 999 965 003</a>
        </div>
      </div>
      <div className="sociaux-media">
        <a href="#">{icons.fb}</a>
        <a href="#">{icons.twitter}</a>
        <a href="#">{icons.linkedin}</a>
        <a href="#">{icons.whatsapp}</a>
      </div>
    </div>
  );
};

export default Header;
