import React from "react";
import "./Presentation.css";

const Presentation = ({ page }) => {
  if (page === "home") {
    return (
      <div className="site-presentation">
        <div className="site-bg-black">
          <div className="text-home">
            <h1 className="rubik-gras">
              Nous adorons l'idée d'offrir d'excellents services et de
              construire la grandeur
            </h1>

            <div className="btn-link">
              <a href="#nos-services" className="btn-page bg-full">
                Nos Services
              </a>
              <a href="#contact" className="btn-page bg-light">
                Contactez-Nous{" "}
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="site-presentation site-presentation-other-page">
      <div className="site-bg-black">
        <div className="text-home">
          <h1 className="rubik-gras">{page}</h1>
        </div>
      </div>
    </div>
  );
};
export default Presentation;
