import { icons } from "../constant";
const configFormLogin = [
  {
    name: "username",
    type: "text",
    value: "",
    icon: icons.user,
    label: "Numero de telephone",
  },
  {
    name: "password",
    type: "password",
    value: "",
    icon: icons.password,
    label: "Mot de passe",
  },
];

const configFormContactezNous = [
  {
    name: "names",
    type: "text",
    value: "",
    icon: icons.user,
    label: "Votre Nom Complet",
  },
  {
    name: "email",
    type: "text",
    value: "",
    icon: icons.email,
    label: "Votre Email",
  },
  {
    name: "message",
    type: "textarea",
    value: "",
    icon: "",
    label: "Votre message",
  },
];

const configFormCreateOffreEmploi = {
  firstOne: [
    {
      name: "title",
      type: "text",
      value: "",
      icon: "",
      label: "Intitulé du poste",
    },
    {
      name: "location",
      type: "text",
      value: "",
      icon: "",
      label: "Lieu d'exercice",
    },

    {
      name: "type",
      type: "select",
      value: "",
      icon: "",
      label: "Type",
      options: [
        { id: 1, description: "Temps plein" },
        { id: 2, description: "Temps partiel" },
        { id: 3, description: "Consultant" },
        { id: 4, description: "Stagiaire" },
      ],
    },
  ],
  secondOne: [
    {
      name: "date_fin",
      type: "date",
      value: "",
      icon: "",
      label: "Date d'expiration",
    },
    {
      name: "file",
      type: "file",
      value: "",
      accept: "application/pdf",
      icon: {
        nodata: icons.download_file,
        data: icons.download_file_true,
      },
      label: {
        nodata: "Selectionner un fichier",
        data: "1 Fichier selectionné",
      },
    },
  ],
};

const configFormCreatePostulerOffreEmploi = {
  firstOne: [
    {
      name: "nom",
      type: "text",
      value: "",
      icon: "",
      label: "Votre nom",
    },
    {
      name: "prenom",
      type: "text",
      value: "",
      icon: "",
      label: "Votre prénom",
    },
    {
      name: "email",
      type: "text",
      value: "",
      icon: "",
      label: "Votre email",
    },
  ],
  secondOne: [
    {
      name: "tel",
      type: "text",
      value: "",
      icon: "",
      label: "Votre numéro de téléphone",
    },
    {
      name: "file",
      type: "file",
      value: "",
      accept: "application/pdf",
      icon: {
        nodata: icons.download_file,
        data: icons.download_file_true,
      },
      label: {
        nodata: "Déposer le fichier unique (PDF) 10Mb Max",
        data: "1 Fichier selectionné",
      },
    },
  ],
};
const configFormFilterStatus = [
  {
    name: "statusId",
    type: "select",
    value: "",
    icon: "",
    label: "Choisir status",
    options: [],
  },
];

const configFormCreateQuestion = {
  firstOne: [
    {
      name: "type",
      type: "select",
      value: "",
      icon: "",
      label: "Choisir Type",
      options: [
        { id: 1, description: "Question d'éligibilité" },
        { id: 2, description: "Question de qualité" },
      ],
    },
    {
      name: "title",
      type: "textarea",
      value: "",
      icon: "",
      label: "Question",
    },
  ],
  secondOne: [
    {
      name: "marks",
      type: "text",
      value: "",
      icon: "",
      label: "Points",
    },
  ],
};

export {
  configFormLogin,
  configFormContactezNous,
  configFormCreateOffreEmploi,
  configFormCreatePostulerOffreEmploi,
  configFormFilterStatus,
  configFormCreateQuestion,
};
