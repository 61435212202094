import React from "react";
import "./Realisation.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { icons, data, urls } from "../../../../constant";
import { NextArrow, PrevArrow } from "../slider";
import { useSelector, useDispatch } from "react-redux";
const Realisation = () => {
  const {
    screens: { isPhonePortrait, isTablette, isDesktop, isBigDesktop },
  } = useSelector((store) => store.data);
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    slidesToShow: isBigDesktop ? 3 : isDesktop ? 3 : isTablette ? 2 : 1,
    speed: 500,
    nextArrow: <NextArrow props />,
    prevArrow: <PrevArrow props />,
  };
  return (
    <div className="realisations">
      <div className="title">
        <h2 className="site-sous-title-h2">
          NOS <span>REALISATIONS</span>
        </h2>
      </div>
      <div>
        <Slider {...settings}>
          {data.nosRealisationsData.map((item, idx) => {
            return <ImageItemGallerie {...item} key={idx} />;
          })}
        </Slider>
      </div>
    </div>
  );
};

const ImageItemGallerie = ({ img, description }) => {
  return (
    <div
      className="realisation_image"
      style={{ backgroundImage: `url(${img})` }}
    >
      <div className="realisation-bg-color">
        <p>{description}</p>
      </div>
    </div>
  );
};

export default Realisation;
