import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setError, setLoading } from "../../../../../global-state/redux";
// import "./Postulant.css";
import { data, urls, menu } from "../../../../../constant";
import { getRequest } from "../../../../../services/httpRequest";
//all components
import { Container, HeaderPager, MenuPager } from "../../../../../components";
import { ActionContent } from "../../../../../reusables";

import { ListePostulantOffreEmploi } from "./small";
const tabs = menu.tabsSitePostulant;
const Postulant = () => {
  const dispatch = useDispatch();
  const [datas, setDatas] = useState("");
  const [status, setStatus] = useState([]);
  const { open, number, actionName } = useSelector((store) => store.modal);
  const { uniqueID } = useSelector((store) => store.unique);
  const { isSaved } = useSelector((store) => store.loading);
  const [tabIndex, setTabIndex] = useState(tabs[0]);

  const tabChanging = (data) => {
    setTabIndex(data);
  };
  const getDatas = async () => {
    dispatch(setLoading());
    const { err, msg, data, typeErr } = await getRequest(
      `${urls.emploi}/${uniqueID}${tabIndex.clause}`
    );
    dispatch(setLoading());
    if (err) {
      return dispatch(setError({ err, msg, data, typeErr }));
    }
    setDatas(data);
  };

  const getStatus = async () => {
    const { err, msg, data, typeErr } = await getRequest(
      `${urls.statique}/status`
    );
    if (err) {
      return dispatch(setError({ err, msg, data, typeErr }));
    }
    setStatus(data);
  };

  useEffect(() => {
    getDatas();
    getStatus();
  }, [isSaved, tabIndex.id]);
  return (
    <>
      <Container pageName={"Postulant"}>
        <HeaderPager
          title={`${tabIndex.description} : ${datas ? datas.title : "-"}`}
          btnTitle="Publier"
        />
        <MenuPager tabs={tabs} fxSelectedtab={tabChanging} />
        {(tabIndex.id === 0 || tabIndex.id === 1 || tabIndex.id === 2) && (
          <ListePostulantOffreEmploi data={datas} />
        )}
      </Container>
      {open && actionName === "actionContent" && (
        <ActionContent datas={status} />
      )}
    </>
  );
};

export default Postulant;
