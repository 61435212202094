import React from "react";
import "./PourquoiNous.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { icons, images, data } from "../../../../constant";
import { NextArrow, PrevArrow } from "../slider";
import { useSelector, useDispatch } from "react-redux";
const PourquoiNous = () => {
  const {
    screens: { isPhonePortrait, isTablette, isDesktop, isBigDesktop },
  } = useSelector((store) => store.data);
  // console.log(screens);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: isBigDesktop ? 3 : isDesktop ? 3 : isTablette ? 2 : 1,
    slidesToScroll: isBigDesktop ? 3 : isDesktop ? 3 : isTablette ? 2 : 1,
    nextArrow: <NextArrow props />,
    prevArrow: <PrevArrow props />,
  };
  return (
    <div className="pourquoi-nous">
      <div className="title">
        <h2 className="site-sous-title-h2">
          POURQUOI NOUS <span>CHOISIR ?</span>
        </h2>
      </div>
      <div>
        <Slider {...settings}>
          {data.pourquoiNousData.map((item, idx) => {
            return <CardPourquoiNous {...item} key={idx} />;
          })}
        </Slider>
      </div>
    </div>
  );
};

const CardPourquoiNous = ({ name, icon, description }) => {
  return (
    <div className="pourquoi_card">
      <div className="pourquoi_card-icon">{icon}</div>
      <div className="pourquoi_card-title">
        <h3 className="site-sous-title-h3">{name}.</h3>
      </div>
      <div className="pourquoi_card-description">
        <p>{description}</p>
      </div>
    </div>
  );
};

export default PourquoiNous;
