import React, { useReducer } from "react";
import "./Contact.css";
import {
  reducer,
  defaultValueFormContactezNous,
  configFormContactezNous,
} from "../../../../reducers";
import { Formulaire } from "../../../../components";
import { TedButton } from "../../../../core-ui";
const Contact = () => {
  const [configForm, dispatchConfig] = useReducer(
    reducer,
    configFormContactezNous
  );
  const [defaultValue, dispatchDefautlValue] = useReducer(
    reducer,
    defaultValueFormContactezNous
  );
  return (
    <div className="contact-nous">
      <div className="conctact-nous-bg-color">
        <div className="contact-empty"></div>
        <div className="contact-form">
          <h3 className="site-sous-title-h3 ">CONTACTEZ NOUS</h3>
          <p>
            Nous sommes simples, efficaces et reconnus pour nos compétences.
            Production de Résultats Nous prenons l’initiative et nous sommes
            toujours à la recherche de résultats! Etes vous interessé par
            Solidec ? Contactez nous
          </p>
          <div>
            <Formulaire
              fields={configForm}
              dispatch={dispatchDefautlValue}
              values={defaultValue}
              align="column"
            />
          </div>
          <div className="save-btn-modal">
            <TedButton
              type="button"
              classe="primary"
              label="ENVOYER"
              loading={false}
              fxOnClick={() => {}}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
