import { data } from "../constant";

const reducer = (state, action) => {
  if (action.type === "CHANGE_CLASS_LOADER_TO_HIDDEN") {
    return {
      ...state,
      loaderClass: "hidden",
      containerClass: "shown",
    };
  }
  if (action.type === "SET_FORM_INPUT_VALUE") {
    let { name, value, multiselect } = action.payload;
    if (typeof multiselect === "object") {
      value = multiselect;
    }
    return {
      ...state,
      [name]: value,
    };
  }
  if (action.type === "SET_TABLES_DATA") {
    const data = action.payload;
    return {
      ...state,
      datas: data,
    };
  }
  if (action.type === "SET_SELECT_STATUS_POSTULANT_OPTIONS") {
    const newState = state;
    const data = action.payload;
    newState[0].options = data;
    return [...newState];
  }
  if (action.type === "SET_DATA_TO_UPDATE_OFFRE_EMPLOI") {
    const newState = state;
    const data = action.payload;
    if (typeof data !== "undefined") {
      const { title, location, type, date_fin } = data;
      newState.title = title;
      newState.location = location;
      newState.type = type;
      newState.date_fin = date_fin;
    } else {
      newState.title = "";
      newState.location = "";
      newState.type = "";
      newState.date_fin = "";
      newState.file = "";
    }
    return {
      ...state,
      ...newState,
    };
  }

  // FOR OTHERS PAST PROJETS

  if (action.type === "SET_SELECT_OPTION_DATA_CATEGORIE_ARTICLE") {
    const newState = state;
    const data = action.payload;
    const categorie = data.map(({ id, translation }) => {
      return {
        id,
        description: translation[0]?.description,
      };
    });
    newState[0].options = categorie;
    return [...newState];
  }
  if (action.type === "SET_DEPENDANT_ARTICLE_FOR_SELECTED_CATEGORIE") {
    const newState = state;
    const data = action.payload;
    var { categId, articles } = data;
    articles = articles.filter((item) => item.categId === categId);
    const art_list = articles.map(({ id, translation }) => {
      return {
        id,
        description: translation[0]?.name,
      };
    });
    newState[1].options = art_list;
    return [...newState];
  }
  if (action.type === "SET_USER_DATA") {
    const newState = state;
    const { provinces, roles } = action.payload;
    const province = provinces.map(({ id, name }) => {
      return {
        id,
        description: name,
      };
    });
    newState.firstOne[2].options = roles;
    newState.fiveOne[0].options = province;

    return {
      ...state,
      ...newState,
    };
  }

  if (action.type === "SET_DEPENDANT_COMMUNE") {
    const newState = state;
    const { provinceId, communes } = action.payload;
    const commune = communes.filter((item) => item.provinceId === provinceId);
    const com = commune.map(({ id, name, provinceId }) => {
      return {
        id,
        description: name,
      };
    });
    newState.fiveOne[1].options = com;
    return {
      ...state,
      ...newState,
    };
  }
  if (action.type === "SET_DEPENDANT_ZONE") {
    const newState = state;
    const { communeId, zones } = action.payload;
    // console.log(communeId, zones);
    const datas = zones.filter((item) => item.communeId === communeId);
    const dt = datas.map(({ id, name, communeId }) => {
      return {
        id,
        description: name,
      };
    });
    newState.thirdOne[0].options = dt;
    return {
      ...state,
      ...newState,
    };
  }
  if (action.type === "SET_DEPENDANT_COLLINE") {
    const newState = state;
    const { zoneId, collines } = action.payload;
    const datas = collines.filter((item) => item.zoneId === zoneId);
    const dt = datas.map(({ id, name, zoneId }) => {
      return {
        id,
        description: name,
      };
    });
    newState.thirdOne[1].options = dt;
    return {
      ...state,
      ...newState,
    };
  }
  if (action.type === "SET_DEPENDANT_LOCALITE") {
    const newState = state;
    const { collineId, localites } = action.payload;
    const datas = localites.filter((item) => item.collineId === collineId);
    const dt = datas.map(({ id, name, collineId }) => {
      return {
        id,
        description: name,
      };
    });
    newState.thirdOne[2].options = dt;
    return {
      ...state,
      ...newState,
    };
  }
  return state;
};

export default reducer;
