import React, { useEffect, useReducer } from "react";
import "./ActionContent.css";
import { TedModal, TedButton } from "../../core-ui";
import { useDispatch, useSelector } from "react-redux";
import {
  setOpenModal,
  setLoading,
  setError,
  setIsSaved,
} from "../../global-state/redux";
import {
  deleteRequest,
  patchRequest,
  postRequest,
} from "../../services/httpRequest";
import { urls } from "../../constant";
import { formatterDateAndHour } from "../../helpers";
import { Formulaire } from "../../components";
import {
  configFormCreatePostulerOffreEmploi,
  defaultValueFormCreatePostulerOffreEmploi,
  configFormFilterStatus,
  defaultValueFormFilterStatus,
  reducer,
} from "../../reducers";

const urlsAction = [
  urls.user,
  urls.produit + "/categorie",
  urls.produit,
  urls.commande,
];
const ActionContent = ({ datas }) => {
  const dispatch = useDispatch();
  const { isloading } = useSelector((store) => store.loading);
  const { open, number, actionName, actionTitle, actionDescription, data } =
    useSelector((store) => store.modal);

  if (number === 0) {
    return <PostulerOffreEmploi dispatch={dispatch} isloading={isloading} />;
  }
  if (number === 1) {
    return (
      <FilterPostulant
        dispatch={dispatch}
        isloading={isloading}
        status={datas}
      />
    );
  }
};
/*
#######
    ACTIONS
#######
*/
const PostulerOffreEmploi = ({ dispatch, isloading }) => {
  const {
    open,
    number,
    actionName,
    actionTitle,
    actionDescription,
    data: dataModal,
  } = useSelector((store) => store.modal);
  const [configForm, dispatchConfig] = useReducer(
    reducer,
    configFormCreatePostulerOffreEmploi
  );
  const [defaultValue, dispatchDefautlValue] = useReducer(
    reducer,
    defaultValueFormCreatePostulerOffreEmploi
  );

  const postulerOffre = async () => {
    dispatch(setLoading());
    var formData = new FormData();
    formData.append("nom", defaultValue.nom);
    formData.append("prenom", defaultValue.prenom);
    formData.append("email", defaultValue.email);
    formData.append("tel", defaultValue.tel);
    formData.append("emploiId", dataModal.id);
    formData.append("file", defaultValue.file);
    const datas = formData;
    const { err, msg, data, typeErr } = await postRequest(
      `${urls.emploi}/postuler`,
      "",
      datas
    );
    if (typeErr === "failed") {
      dispatch(setLoading());
      return dispatch(setError({ err, msg, data, typeErr }));
    }
    dispatch(setLoading());
    dispatch(setError({ err, msg, data, typeErr }));
    dispatch(setOpenModal({ number: 0 }));
    return dispatch(setIsSaved());
  };
  return (
    <TedModal
      title={actionTitle}
      isOpen={open}
      fxCloseAndOpen={() => dispatch(setOpenModal({ number: 0 }))}
      size="moyen"
    >
      {dataModal.state < 1 ? (
        <>
          <div className="form-postuler">
            <div>
              <Formulaire
                fields={configForm.firstOne}
                dispatch={dispatchDefautlValue}
                values={defaultValue}
                align="column"
              />
            </div>
            <div>
              <Formulaire
                fields={configForm.secondOne}
                dispatch={dispatchDefautlValue}
                values={defaultValue}
                align="column"
              />
            </div>
          </div>
          <div className="save-btn-modal">
            <TedButton
              type="button"
              classe="primary"
              label="ENVOYER"
              loading={isloading}
              fxOnClick={() => postulerOffre()}
            />
          </div>
        </>
      ) : (
        <p className="p-expirer">
          Impossible de postuler à cette offre car elle est déjà expirée !
        </p>
      )}
    </TedModal>
  );
};
const FilterPostulant = ({ dispatch, isloading, status }) => {
  const {
    open,
    number,
    actionName,
    actionTitle,
    actionDescription,
    data: dataModal,
  } = useSelector((store) => store.modal);
  const [configForm, dispatchConfig] = useReducer(
    reducer,
    configFormFilterStatus
  );
  const [defaultValue, dispatchDefautlValue] = useReducer(
    reducer,
    defaultValueFormFilterStatus
  );
  const changeStatus = async () => {
    dispatch(setLoading());
    const datas = { ...defaultValue, postulantId: dataModal.id };
    const { err, msg, data, typeErr } = await postRequest(
      `${urls.postulant}/filter`,
      "",
      datas
    );
    if (typeErr === "failed") {
      dispatch(setLoading());
      return dispatch(setError({ err, msg, data, typeErr }));
    }
    dispatch(setLoading());
    dispatch(setError({ err, msg, data, typeErr }));
    dispatch(setOpenModal({ number: 0 }));
    return dispatch(setIsSaved());
  };
  useEffect(() => {
    dispatchConfig({
      type: "SET_SELECT_STATUS_POSTULANT_OPTIONS",
      payload: status,
    });
  }, []);
  return (
    <TedModal
      title={actionTitle}
      isOpen={open}
      fxCloseAndOpen={() => dispatch(setOpenModal({ number: 0 }))}
      size="moyen-01"
    >
      <div className="form-postuler">
        <Formulaire
          fields={configForm}
          dispatch={dispatchDefautlValue}
          values={defaultValue}
          align="column"
        />
      </div>
      <div className="save-btn-modal">
        <TedButton
          type="button"
          classe="primary"
          label="CHANGER"
          loading={isloading}
          fxOnClick={() => changeStatus()}
        />
      </div>
    </TedModal>
  );
};

export default ActionContent;
