import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import { Route, Switch, Redirect } from "react-router-dom";
//all pages
import {
  Login,
  Error,
  Main,
  HomeWebSite,
  AppelOffresSite,
  OffresEmploiSite,
  DashboardAppSite,
  EmploiAppSite,
  PostulantAppSite,
  QuestionsAppSite,
} from "./pages";
import { useSelector, useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { setScreens } from "./global-state/redux";
const App = () => {
  const isPhonePortrait = useMediaQuery({ query: "(min-width: 576px)" });
  const isTablette = useMediaQuery({ query: "(min-width: 768px)" });
  const isDesktop = useMediaQuery({ query: "(min-width: 992px)" });
  const isBigDesktop = useMediaQuery({ query: "(min-width: 1200px)" });

  const dispatch = useDispatch();
  const { isConnected } = useSelector((store) => store.user);
  useEffect(() => {
    dispatch(
      setScreens({
        isPhonePortrait,
        isTablette,
        isDesktop,
        isBigDesktop,
      })
    );
  }, [isPhonePortrait, isTablette, isDesktop, isBigDesktop]);

  return (
    <Switch>
      {/* ROUTE FOR web site */}
      <Route exact path="/">
        <HomeWebSite />
      </Route>
      <Route exact path="/appels-offres">
        <AppelOffresSite />
      </Route>
      <Route exact path="/offres-emploi">
        <OffresEmploiSite />
      </Route>
      <Route exact path="/login">
        <Login />
      </Route>
      {/* route for choose app */}
      <Route
        exact
        path="/main"
        render={() => (isConnected ? <Main /> : <Redirect to={"/login"} />)}
      />

      {/* routes for app : web site */}
      <Route
        exact
        path="/site/dashboard"
        render={() =>
          isConnected ? <DashboardAppSite /> : <Redirect to={"/login"} />
        }
      />
      <Route
        exact
        path="/site/offres-emploi"
        render={() =>
          isConnected ? <EmploiAppSite /> : <Redirect to={"/login"} />
        }
      />
      <Route
        exact
        path="/site/postulants"
        render={() =>
          isConnected ? <PostulantAppSite /> : <Redirect to={"/login"} />
        }
      />
      <Route
        exact
        path={"/site/questions"}
        render={() =>
          isConnected ? <QuestionsAppSite /> : <Redirect to={"/login"} />
        }
      />
      <Route exact path="*">
        <Error />
      </Route>
    </Switch>
  );
};

export default App;
