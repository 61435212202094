import React, { useState } from "react";
import "./Menu.css";
import { useHistory } from "react-router-dom";
import { menu, icons } from "../../../../constant";
import { Link as LinkDom } from "react-router-dom";
import { Anchor, Dropdown, Menu as DropMenu } from "antd";
import { useEffect } from "react";
const { Link } = Anchor;

const Menu = () => {
  const [showMenu, setShowMenu] = useState(false);
  const history = useHistory();
  const path = history.location.pathname;

  const closeMenu = () => {
    setShowMenu(() => {
      return !showMenu;
    });
  };
  useEffect(() => {
    console.log(path);
  }, [path]);
  return (
    <nav className="menu-site">
      <Anchor affix={false}>
        <ul className="web">
          {menu.menuSite.map(({ name, link, anchor, sousMenu }, idx) => {
            return (
              <li key={idx}>
                {sousMenu.length < 1 ? (
                  <Link href={link} title={name} className="site-link" />
                ) : (
                  <Dropdown overlay={menuDrop} placement="bottom" arrow>
                    <span>{name}</span>
                  </Dropdown>
                )}
              </li>
            );
          })}
        </ul>

        <div className="mobile">
          <span onClick={() => closeMenu()}>{icons.menuMob}</span>
          {showMenu && (
            <div className="menu-mobile_content">
              <div>
                <ul className="mob">
                  {menu.menuSiteMob.map(
                    ({ name, link, anchor, sousMenu }, idx) => {
                      return (
                        <li key={idx}>
                          <div onClick={() => closeMenu()}>
                            <Link
                              href={link}
                              title={name}
                              className="site-link"
                            />
                          </div>
                        </li>
                      );
                    }
                  )}
                </ul>
              </div>
              <div onClick={() => closeMenu()}>
                <span>{icons.close_menu}</span>
              </div>
            </div>
          )}
        </div>
      </Anchor>
    </nav>
  );
};

const menuDrop = (
  <DropMenu
    items={[
      {
        key: "1",
        label: (
          <LinkDom to={"/offres-emploi"} className="site-link-dropdown">
            Offres d'emploi
          </LinkDom>
        ),
      },
      {
        key: "2",
        label: (
          <LinkDom to={"/appels-offres"} className="site-link-dropdown">
            Appels d'offres
          </LinkDom>
        ),
      },
    ]}
  />
);
export default Menu;
